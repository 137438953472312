import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Section } from 'src/components/molecules/Section';
import { Link } from 'src/containers/Link';
import { FluidImg } from 'src/embeds/image/image-dato';
import { Grid } from 'src/system';

export default function Drawer({ preHeading, contentNode, heading, ctas, customData, image }) {
  customData = JSON.parse(customData);
  const cta = ctas[0];
  return (
    <Section {...customData?.flags}>
      <Grid className="cols-auto">
        {image && (
          <FluidImg data={image} maxWidth={image.customData?.maxWidth || 640} />
        )}
        <div>
          <div>{preHeading}</div>
          <h2>{heading}</h2>
          <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>
          <p>
            <strong>
              <Link to={cta.url}>{cta.text}</Link>
            </strong>
          </p>
        </div>
      </Grid>
    </Section>
  );
}
